.textAlign {
    text-align: center;
}

.Add_Buttons_color {
    background-color: #264563;
}

.Add_Buttons_color:hover {
    background-color: #2873bd;
}

.textFied_left {
    width: 47%;
    margin-right: 6%;
}

.textFied_right {
    width: 47%;
}

.buttons-flex {
    display: inline-flex !important;
}

.pointer_margin10 {
    margin-right: 10%;
    margin-bottom: 5px;
    cursor: pointer;
}

.pointer_margin5 {
    margin-right: 5%;
    margin-bottom: 5px;
    cursor: pointer;
}

/* tranlator details view */
.t_details {
    font-weight: bolder;
    font-size: 15px;
    padding-right:35px;
}

._card {
    width: max-content;
    height: auto;
    margin: 30px 50px 30px 50px;
    overflow: scroll
}

.view_data {
    font-weight: bold;
    font-size: 18px;
    font-family: inherit;
}

.view_padding{
    padding-left: "5%";
    padding-right: "5px";
}
.close_icon{
    margin-right:25%; padding-right:0;
    margin-left:auto; float:right;
}
.changelog_div{
    margin-top:2%; margin-bottom:1%;
    padding-left:5%;
}

.changelog_dbText{
    background-color: #264563;
    margin-left:17px;
    margin-right:45px;
    padding-top:2%;
    padding-bottom:2%;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 16px;
}
.changelog_heading{
    /* background-color: #3b536b; */

    padding-left:2%;
    font-size:14px;
    font-weight: bolder;
}
.rightAlignButton1{
    margin-left:auto;
    margin-right:10px;
    float: right;
    display: flex; 
    justify-content: flex-end;
    background-color: #264563;
}
.rightAlignButton1:hover{
    background-color: #2873bd;;
}
.rightAlignButton2{
    float: right;
    display: flex; 
    justify-content: flex-end;
    background-color: #264563;
}
.rightAlignButton2:hover{
    background-color: #2873bd;;
}
.bold_heading{
    font-weight: bold;
    font-size:medium;
}
.dashboardIcons{
    padding-top:10px;margin-right:10px;
}

.dashboardContent{
    color: white;
}

.dashboardItems{
    font-weight:bold; font-size:24px;
}
.dashboardItems:hover{
    color:darkgoldenrod;
}
.aTag{
    text-decoration:none;
    color: black;
}
.aTag:hover{
    text-decoration:none;
    color:darkgoldenrod;
}
/* @media screen and (min-width:250px) {
    .bgimg {
      background-image: url('../src/pages/pages/images/bg.jpg');
    background-attachment:fixed;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center ;
    position: relative;
    height: 70vh;
    width: 100%;
    } */
/* } */
    @media screen and (min-width: 768px) {
        .textResponsive {
          font-size: 2.5vh;
          color: black;
        }
      }
      @media screen and (max-width: 767px) {
        .textResponsive {
          font-size: 14px;
        }
      }

  @media screen and (min-width:250px) {
    .logo_icons {
      
    width:4vh;
    height:5vh;
    position: center;
    /* height: 100vh; */
   
    }
}

    @media screen and (min-width:250px) {
        .icons {
            width:60px;
            height: 80px;
            padding: 20px 0 10px 0;
            margin-left: 20px;
        }
  }

  @media screen and (max-width:1000px) {
    .icons {
        width:80px;
        height:110px;
    }
  }
    @media screen and (min-width:250px) {
        .dashboardIcons_font {
           font-size: 3vh;
           font-weight: bold;
           padding-top: 5vh;
           padding-left: 20px;
           color:aliceblue;
        }
  }

  @media screen and (max-width:1000px) {
    .dashboardIcons_font {
        font-size: 1.5vh;
        font-weight: bold;
        padding-top: 30px;
        color:aliceblue;
    }
   
}
.dashboardMainDiv{
    width:100%; display:flex;
    height: auto;
}
.dashboardSecondDiv{
    width:41vh; 
    padding-right:20px;
    height:20vh;
     border-radius:5px; 
     display:flex;
     margin-left:20px;
     
     background-repeat: no-repeat;
     background-attachment:relative;
     background-size: 100% 100%;
     
}
.dashboardFirstDiv{
    width:41vh; 
    padding-right:20px;
    height:20vh;
     border-radius:5px; 
     display:flex;
   
     background-attachment:relative;
     background-size: 100% 100%;
  
}
.hover-pointer :hover{
cursor: pointer;
color: rgb(3, 53, 102);
}